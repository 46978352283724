import { Injectable } from '@angular/core';
import { ResourceHttpService } from '../shared/resource-http.service';
import { HttpClient } from '@angular/common/http';
import { TempFileModel } from '../../../../pss2-shared/apiModels/index';
import { SiteReport } from '../../../../pss2-shared/siteReport';

@Injectable({
	providedIn: 'root',
})
export class SiteReportsHttpService extends ResourceHttpService {
	constructor(http: HttpClient) {
		super(http, 'tools/site-reports');
	}

	downloadCsv(params = {}) {
		return this.http.get<TempFileModel>(`${this.entityUrl}/csv`, params);
	}

	complete(data: Partial<SiteReport>, justStart = false) {
		return this.http.post<{ id: number }>(`${this.entityUrl}/complete`, data, { params: { justStart } });
	}

	userSiteReports() {
		return this.http.get<SiteReport[]>(`${this.entityUrl}/active-site-reports`);
	}

	deleteOne(siteReportId: number) {
		return this.http.delete(`${this.entityUrl}/${siteReportId}`);
	}

	deleteMany(siteReportIds: number[]) {
		return this.http.delete(`${this.entityUrl}/batch-remove`, { body: { siteReportIds } });
	}

	officeSiteReportForms() {
		return this.http.get<any>(`${this.entityUrl}/office-site-report-forms`);
	}

	officeSiteReportFormsByJob(jobId: number) {
		return this.http.get<any>(`${this.entityUrl}/office-site-report-forms-job/${jobId}`);
	}

	searchJobs(params: any) {
		return this.http.get<any>(`${this.entityUrl}/jobs`, { params });
	}

	getAttachments(todoId: number) {
		return this.http.get(`${this.entityUrl}/${todoId}/attachments`);
	}

	uploadAttachment(todoId: number, fd: FormData) {
		return this.http.post(`${this.entityUrl}/${todoId}/attachments`, fd);
	}

	deleteAttachment(todoId: number, fileId: number) {
		return this.http.delete(`${this.entityUrl}/${todoId}/attachments/${fileId}`);
	}

	print(id: number) {
		return this.http.get<TempFileModel>(`${this.entityUrl}/${id}/print`);
	}

	getSiteReportHistory(siteReportId: number, params) {
		return this.http.get<any>(`${this.entityUrl}/${siteReportId}/history`, { params });
	}
}
