import { Injectable } from '@angular/core';
import { ResourceHttpService } from 'src/app/shared/resource-http.service';
import { HttpClient } from '@angular/common/http';
import {
	EmployeeTrackTimeDaySelf,
	JobDay,
	JobDayBlockEmployeeEditRequest,
	JobLockDailyResponse,
	JobTrackTimeTrucksWriteQuery,
	JobTrackTimeWriteQuery,
	JobWithDays,
} from '../../../../../pss2-shared/apiModels/jobModels';
import { DAILY_LOCK_REQUEST, INVOICE_CREATION_MODE } from '../../../../../pss2-shared/sharedConstants';
import { BidTimelineEvent, EmailTemplateConfig, TempFileModel } from '../../../../../pss2-shared/apiModels/index';
import { Observable } from 'rxjs';
import { InvoiceDocumentsGetConfig, InvoiceDocumentsPostConfig } from '../../../../../pss2-shared/jobInvoice';

@Injectable({
	providedIn: 'root',
})
export class JobsHttpService extends ResourceHttpService {
	constructor(http: HttpClient) {
		super(http, 'workflow/jobs');
	}

	search(params: any) {
		return this.http.get<any>(`${this.entityUrl}/search`, { params });
	}

	toggleArchive(id: number, isActive: boolean) {
		return this.http.post(`${this.entityUrl}/${id}/toggleArchive`, { isActive });
	}

	deleteOne(id: number) {
		return this.http.delete(`${this.entityUrl}/${id}`);
	}

	getBidTimelineEvents(jobId: number) {
		return this.http.get<BidTimelineEvent[]>(`${this.entityUrl}/${jobId}/timeline-events`);
	}

	getJobWithDays(jobId: number) {
		return this.http.get<JobWithDays>(`${this.entityUrl}/${jobId}/daily`);
	}

	getJobEvents(jobId: number) {
		return this.http.get<any[]>(`${this.entityUrl}/${jobId}/events`);
	}

	getJobDay(jobId: number, dayId: number) {
		return this.http.get<JobDay>(`${this.entityUrl}/${jobId}/daily/${dayId}`);
	}

	lockJobDaily(jobId: number) {
		return this.http.post<{ daysUnlocked: boolean }>(`${this.entityUrl}/${jobId}/daily/lock-daily`, {});
	}

	lockJobDay(jobId: number, dayId: number, type: DAILY_LOCK_REQUEST) {
		return this.http.post<JobLockDailyResponse>(`${this.entityUrl}/${jobId}/daily/${dayId}/lock-day`, { type });
	}

	toggleArchiveJobDay(dayId: number, isActive: boolean, uuidStamp: string) {
		return this.http.post(`${this.entityUrl}/daily/${dayId}/toggleArchiveJobDay`, { isActive, uuidStamp });
	}

	deleteJobDay(dayId: number) {
		return this.http.delete<{ id: number }>(`${this.entityUrl}/daily/${dayId}`);
	}

	saveJobDay(day: Partial<JobDay>) {
		return this.http.post<{ id: number }>(`${this.entityUrl}/daily`, day);
	}

	verifyJobDay(dayId: number, isVerified: boolean) {
		return this.http.put<{ id: number; isVerified: boolean; verifiedBy: number }>(`${this.entityUrl}/daily/${dayId}/verify`, { isVerified });
	}

	getInvoices(jobId: number) {
		return this.http.get<any[]>(`${this.entityUrl}/${jobId}/invoices`);
	}

	getInvoice(jobId: number, invoiceId: number) {
		return this.http.get(`${this.entityUrl}/${jobId}/invoices/${invoiceId}`);
	}

	getNewInvoice(jobId: number, mode: INVOICE_CREATION_MODE = INVOICE_CREATION_MODE.BID_ITEMS) {
		return this.http.get<any[]>(`${this.entityUrl}/${jobId}/new-invoice`, { params: { mode } });
	}

	saveInvoice(invoice: any) {
		return this.http.post<any>(`${this.entityUrl}/invoices`, invoice);
	}

	getInvoiceAttachmentConfig(jobId: number, invoiceId: number) {
		return this.http.get<InvoiceDocumentsGetConfig>(`${this.entityUrl}/${jobId}/invoices/get-attachment-config/${invoiceId}`);
	}

	printInvoice(jobId: number, invoiceId: number, attachmentConfig: InvoiceDocumentsPostConfig) {
		return this.http.post<any>(`${this.entityUrl}/${jobId}/invoices/print/${invoiceId}`, { attachmentConfig });
	}

	getInvoiceAttachments(jobId: number, invoiceId: number) {
		return this.http.get<any>(`${this.entityUrl}/${jobId}/invoices/${invoiceId}/attachments`);
	}

	uploadInvoiceAttachments(jobId: number, invoiceId: number, fd: FormData) {
		return this.http.post<any>(`${this.entityUrl}/${jobId}/invoices/${invoiceId}/attachments`, fd);
	}

	removeInvoiceAttachment(jobId: number, invoiceId: number, fileId: number) {
		return this.http.delete<any>(`${this.entityUrl}/${jobId}/invoices/${invoiceId}/attachments/${fileId}`);
	}

	sendInvoiceByEmail(jobId: number, invoiceId: number, config) {
		return this.http.post<any>(`${this.entityUrl}/${jobId}/invoices/email/${invoiceId}`, config);
	}

	exportInvoiceToQbDesktop(jobId: number, invoiceId: number) {
		return this.http.post<any>(`${this.entityUrl}/${jobId}/invoices/qb-desktop/${invoiceId}`, {});
	}

	exportEstimateToQbDesktop(jobId: number, invoiceId: number) {
		return this.http.post<any>(`${this.entityUrl}/${jobId}/estimates/qb-desktop/${invoiceId}`, {});
	}

	deleteInvoice(jobId: number, invoiceId: number) {
		return this.http.delete<any[]>(`${this.entityUrl}/${jobId}/invoices/${invoiceId}`);
	}

	printNotesPDF(jobId: number) {
		return this.http.get<any>(`${this.entityUrl}/${jobId}/notes/print`);
	}

	convertJobToSimpleDaily(jobId: number) {
		return this.http.put(`${this.entityUrl}/${jobId}/convert-to-simple-daily`, {});
	}

	getTrackTime<T>(params: any): any {
		return this.http.get<T[]>(`${this.entityUrl}/track-time`, { params });
	}

	getTrackTimeCsv(params: any): Observable<TempFileModel> {
		return this.http.get<TempFileModel>(`${this.entityUrl}/track-time/csv`, { params });
	}

	saveTrackTime(data: JobTrackTimeWriteQuery) {
		return this.http.post(`${this.entityUrl}/track-time`, data);
	}

	getTrackTimeTrucks<T>(params: any): any {
		return this.http.get<T[]>(`${this.entityUrl}/track-time-trucks`, { params });
	}

	getTrackTimeTrucksCsv(params: any): Observable<TempFileModel> {
		return this.http.get<TempFileModel>(`${this.entityUrl}/track-time-trucks/csv`, { params });
	}

	saveTrackTimeTrucks(data: JobTrackTimeTrucksWriteQuery) {
		return this.http.post(`${this.entityUrl}/track-time-trucks`, data);
	}

	setBidItemJobStatus(jobId: number, bidItemId: number, statusId: number) {
		return this.http.put<any>(`${this.entityUrl}/${jobId}/${bidItemId}/status`, { statusId });
	}

	getPaidSurveyEmail(jobId: number) {
		return this.http.get<any>(`${this.entityUrl}/${jobId}/paid-survey-email`);
	}

	invitePaidSurvey(jobId: number, emailTemplate: EmailTemplateConfig, senderId: number) {
		return this.http.post(`${this.entityUrl}/${jobId}/invite-paid-survey`, { emailTemplate, senderId });
	}

	getJobHistory(jobId: number, params) {
		return this.http.get(`${this.entityUrl}/${jobId}/history`, { params });
	}

	getJobsDocumentsCount(jobId: number, jobDayId?: number, jobDayBlockDirectCostId?: number) {
		const params: any = {};
		if (jobDayId) Object.assign(params, { jobDayId });
		if (jobDayBlockDirectCostId) Object.assign(params, { jobDayBlockDirectCostId });
		return this.http.get<{[key: string]: number}>(`${this.entityUrl}/${jobId}/get-documents-count`, { params });
	}

	getJobBidItems(jobId: number) {
		return this.http.get<any>(`${this.entityUrl}/${jobId}/job-bid-items`);
	}

	getJobDailyBidItems(jobDayId: number) {
		return this.http.get<any>(`${this.entityUrl}/${jobDayId}/job-day-bid-items`);
	}

	getDailyReportDocumentsConfig(jobId: number) {
		return this.http.get<any>(`${this.entityUrl}/${jobId}/daily-report/documents-config`);
	}

	getDailyReportWithExtensiveDetails(
		jobId: number,
		days: number[],
		documentsIds: number[],
		isRelatedDocumentsIncluded: boolean,
		isSiteReportIncluded: boolean,
		isUnlinkedRemovedNotesIncluded: boolean,
		isConsolidated = false
	) {
		return this.http.post<TempFileModel>(`${this.entityUrl}/${jobId}/daily-report`, {
			days,
			documentsIds,
			isRelatedDocumentsIncluded,
			isSiteReportIncluded,
			isUnlinkedRemovedNotesIncluded,
			isConsolidated,
		});
	}

	getSiteReportForms(jobId: number, params = {}) {
		return this.http.get<any>(`${this.entityUrl}/${jobId}/site-report-forms`, { params });
	}

	deleteEventNotes(jobId: number, eventIds: number[]) {
		return this.http.post(`${this.entityUrl}/${jobId}/delete-event-notes`, {
			eventIds,
		});
	}

	getEmailManagerData(jobId: number) {
		return this.http.get<{ managerId: number; emailConfig: EmailTemplateConfig }>(`${this.entityUrl}/${jobId}/email-manager-data`);
	}

	emailManager(jobId: number, body: { senderId: number; emailTemplate: EmailTemplateConfig }) {
		return this.http.post(`${this.entityUrl}/${jobId}/email-manager`, body);
	}

	getTrackTimeEditRequests() {
		return this.http.get<JobDayBlockEmployeeEditRequest[]>(`${this.entityUrl}/track-time-edit-requests`);
	}

	declineTrackTimeEditRequest(id: string) {
		return this.http.post(`${this.entityUrl}/track-time-edit-requests/${id}/decline`, {});
	}

	trackTimeEmployeeSelf(date: string) {
		return this.http.get<EmployeeTrackTimeDaySelf>(`${this.entityUrl}/track-time-employee-self`, { params: { date } })
	}
}
