import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Modal } from 'src/app/shared/modal-service-models/modal.model';
import { Subject, takeUntil } from 'rxjs';
import { UsersHttpService } from 'src/app/company-settings/services/users-http.service';
import { AuthState } from 'src/app/auth/store/auth.store';
import { LoadActiveTrackTimeRequests, TrackTimeDeclineRequest } from '../../store/track-time-edit-requests.actions';
import { TrackTimeEditRequestStore } from '../../store/track-time-edit-requests.store';
import { JobDayBlockEmployeeEditRequest } from '@pss2-shared/apiModels/jobModels';
import { Router } from '@angular/router';

@Component({
	selector: 'app-user-active-track-time-edit-requests-dlg',
	templateUrl: './user-active-track-time-edit-requests-dlg.component.html',
	styleUrls: ['./user-active-track-time-edit-requests-dlg.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserActiveTrackTimeEditRequestsDlgComponent extends Modal implements OnInit, OnDestroy {
	isLoading = true;

	subject$ = new Subject<void>();

	editRequests: JobDayBlockEmployeeEditRequest[] = [];

	users = [];

	user;

	constructor(
		private store: Store,
		private cd: ChangeDetectorRef,
		private usersHttpService: UsersHttpService,
		private router: Router,
	) {
		super();
		this.user = this.store.selectSnapshot(AuthState.user);
	}

	ngOnInit() {
		this.store.dispatch(new LoadActiveTrackTimeRequests());

		this.store
			.select(TrackTimeEditRequestStore.isEditRequestLoading)
			.pipe(takeUntil(this.subject$))
			.subscribe((isLoading) => {
				this.isLoading = isLoading;
				this.cd.markForCheck();
			});

		this.store
			.select(TrackTimeEditRequestStore.editRequests)
			.pipe(takeUntil(this.subject$))
			.subscribe((editRequests) => {
				this.editRequests = editRequests;
				this.cd.markForCheck();
			});

		this.usersHttpService.getAll({ showInactive: true }).subscribe((users) => {
			this.users = users;
			this.cd.markForCheck();
		});
	}

	ngOnDestroy(): void {
		this.subject$.next();
		this.subject$.complete();
	}

	onInjectInputs() {}

	getUserNames(userIds: number[]) {
		return this.users
			.filter((u) => userIds.includes(u.id))
			.map((u) => u.name)
			.join(', ');
	}

	getUserIds(users: { id: number }[]) {
		return users.map((u) => u.id);
	}

	navigateToTheDaily(editRequest: JobDayBlockEmployeeEditRequest) {
		this.router.navigate([`/workflow/jobs/edit/${editRequest.jobId}/daily/edit/${editRequest.dayId}`], { queryParams: { block: editRequest.blockId, isAccountant: editRequest.isAccountant, employees: true } })
		this.close(null);
	}

	declineTrackTimeRequest(id: string) {
		this.store.dispatch(new TrackTimeDeclineRequest({ id }));
	}

	cancel() {
		this.close(null);
	}
}
