export enum TrackTimeRequestsActionTypes {
	LoadActiveTrackTimeRequests = '[Track Time Requests] Load Active Track Time Requests',
	TrackTimeRequestsSetIsLoading = '[Track Time Requests] Set Is Loading',
	DeclineTrackTimeEditRequest = '[Track Time Requests] Decline Track Time Request',
}

export class LoadActiveTrackTimeRequests {
	static readonly type = TrackTimeRequestsActionTypes.LoadActiveTrackTimeRequests;
}

export class TrackTimeRequestsSetIsLoading {
	static readonly type = TrackTimeRequestsActionTypes.TrackTimeRequestsSetIsLoading;
	constructor(public payload: { isLoading: boolean }) {}
}

export class TrackTimeDeclineRequest {
	static readonly type = TrackTimeRequestsActionTypes.DeclineTrackTimeEditRequest;
	constructor(public payload: { id: string }) {}
}
