import { Address } from './address';
import { Phone } from './phone';

/**
 * format google address components to uniform addresses
 */
export function decodeGoogleAddress(components: any[], location: { lat: number; lng: number }): Address {
	const subpremise = components.find((c) => c.types.indexOf('subpremise') >= 0);
	const streetNumber = components.find((c) => c.types.indexOf('street_number') >= 0);
	const street = components.find((c) => c.types.indexOf('route') >= 0);
	const city = components.find((c) => c.types.indexOf('locality') >= 0) || components.find((c) => c.types.indexOf('sublocality') >= 0);
	const state = components.find((c) => c.types.indexOf('administrative_area_level_1') >= 0);
	const country = components.find((c) => c.types.indexOf('country') >= 0);
	const postalCode = components.find((c) => c.types.indexOf('postal_code') >= 0);

	// Insert ' ' as separator between street parts only when 2 parts is present
	const hasStreetNumber = streetNumber?.long_name;
	const hasStreet = street?.short_name;
	const formattedStreet =
		hasStreetNumber && hasStreet ? `${streetNumber.long_name} ${street.short_name}` : `${street?.long_name ?? ''}${street?.short_name ?? ''}`;

	return {
		postalCode: postalCode?.long_name ?? '',
		state: state?.short_name ?? '',
		country: country?.short_name ?? '',
		city: city?.long_name ?? '',
		suite: subpremise?.short_name ?? '',
		street: formattedStreet,
		geolocation: location
			? {
					lat: location.lat,
					lng: location.lng,
				}
			: null,
	};
}

export function calculateRatioPercents(total: number, part: number) {
	return roundValue(total ? (part * 100) / total : 100);
}

export function calculatePercents(value: number, pct: number) {
	return (value * pct) / 100;
}

export function addPercents(value: number | string, percents: number | string): number {
	const v = safeParseFloat(value);
	const p = safeParseFloat(percents);
	return roundValue(v + (v * p) / 100, 2);
}

export function subtractPercents(value: number, pct: number) {
	return value - calculatePercents(value, pct);
}

export function subtractMarkup(value: number, pct: number) {
	return value / (1 + pct / 100);
}

export function calculateProfitPercentage(billing: number, expenses: number, withHundreds = false) {
	if (!billing) return 0;
	const res = roundValue(((billing - expenses) / billing) * 100, withHundreds ? 2 : 0);
	return res;
}

export function calculateProfitMarginPercentage(grandTotal: number, expenses: number) {
	const profit = grandTotal - expenses;
	return Math.ceil((grandTotal ? profit / grandTotal : 0) * 100);
}

//copied from https://github.com/sindresorhus/round-to/blob/master/index
export function roundValue(input: number, precision: number = 0) {
	const isRoundingAndNegative = input < 0;
	const newInput = isRoundingAndNegative ? Math.abs(input) : input;

	let [num, exponent] = `${newInput}e`.split('e');
	let res = Math.round(parseFloat(`${num}e${Number(exponent) + precision}`));

	[num, exponent] = `${res}e`.split('e');
	res = Number(`${num}e${Number(exponent) - precision}`);

	if (isRoundingAndNegative) {
		res = -res;
	}

	return res;
}

export function safeParseFloat(v): number {
	let r = parseFloat(v);
	if (!r || !isFinite(r)) r = 0;
	return r;
}

export function ObjectToAddress(data: any) {
	const address: Address = {
		country: data?.country ?? null,
		postalCode: data?.postalCode ?? null,
		state: data?.state ?? null,
		city: data?.city ?? null,
		street: data?.street ?? null,
		suite: data?.suite ?? null,
		geolocation: data?.geolocation ?? null,
	};
	return address;
}

export function addressToString(address: Address, separator = ' ') {
	if (!address) return '';
	const parts = [];
	if (address.street) parts.push(address.street);
	if (address.suite) parts.push(address.suite);
	let tmp = '';
	if (address.city) tmp += address.city;
	if (address.state) tmp += ', ' + address.state;
	if (tmp) parts.push(tmp);
	if (address.postalCode) parts.push(address.postalCode);
	if (address.country) parts.push(address.country);
	return parts.join(separator);
}

export function addressToStreetSuiteString(address?: Address): string {
	return [address?.street?.trim(), address?.suite?.trim()].filter(Boolean).join(' ');
}

export function phonesToString(phones: Phone[], separator = ', ') {
	if (!phones) return '';
	return phones
		.filter((phone) => phone.number)
		.map((phone) => {
			return phone.type ? `${phone.type}: ${phone.number}` : phone.number;
		})
		.join(separator);
}

export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
	const chunks: T[][] = [];
	while (array.length) {
		chunks.push(array.splice(0, chunkSize));
	}
	return chunks;
}

export function getReadableBoolean(flag: boolean) {
	return flag ? 'Yes' : 'No';
}

export const isValidDate = (date: Date) => date instanceof Date && !isNaN(date.getTime());

export const notNegativeNumber = (value: number) => Math.max(value, 0);

export const feetInchesToFeet = (feet: number, inches: number) => {
	return roundValue(feet + inches / 12, 2);
};

export const feetToFootAndInches = (value: number) => {
	const feet = Math.trunc(value);
	const inches = roundValue((value - feet) * 12, 2);
	return { feet, inches };
};

export function buildEmailBody(body: string, signature = '') {
	const trimmedBody = body.trim();
	const trimmedSignature = signature.trim();

	if (trimmedSignature) {
		return `${trimmedBody}\n\n${trimmedSignature}`;
	}

	return `${trimmedBody}`;
}

export function truncateToTwoDecimalPlaces(value: number) {
	return Math.trunc(value * 100) / 100;
}
  