import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, filter, tap } from 'rxjs';
import { SetDefaultState } from 'src/app/main-app.actions';
import { JobDayBlockEmployeeEditRequest } from '@pss2-shared/apiModels/jobModels';
import { JobsHttpService } from '../services/jobs-http.service';
import { LoadActiveTrackTimeRequests, TrackTimeDeclineRequest, TrackTimeRequestsSetIsLoading } from './track-time-edit-requests.actions';

export class TrackTimeEditRequestModel {
	editRequests: JobDayBlockEmployeeEditRequest[];
	isLoading: boolean;
}

@State<TrackTimeEditRequestModel>({
	name: 'trackTimeEditRequest',
	defaults: {
		editRequests: [],
		isLoading: false,
	},
})
@Injectable()
export class TrackTimeEditRequestStore {
	constructor(private jobsHttpService: JobsHttpService) {}

	@Selector()
	static editRequests(state: TrackTimeEditRequestModel) {
		return state.editRequests;
	}

	@Selector()
	static isEditRequestLoading(state: TrackTimeEditRequestModel) {
		return state.isLoading;
	}

	@Action(LoadActiveTrackTimeRequests)
	loadActiveTrackTimeRequests(ctx: StateContext<TrackTimeEditRequestModel>) {
		return this.jobsHttpService
			.getTrackTimeEditRequests()
			.pipe(
				filter(() => !ctx.getState().isLoading),
				tap(() => ctx.dispatch(new TrackTimeRequestsSetIsLoading({ isLoading: true }))),
				catchError(() => {
					ctx.dispatch(new TrackTimeRequestsSetIsLoading({ isLoading: false }));
					return [];
				})
			)
			.subscribe((editRequests) => {
				ctx.patchState({ editRequests });
				ctx.dispatch(new TrackTimeRequestsSetIsLoading({ isLoading: false }));
			});
	}

	@Action(TrackTimeRequestsSetIsLoading)
	trackTimeRequestsSetIsLoading(ctx: StateContext<TrackTimeEditRequestModel>, { payload }: TrackTimeRequestsSetIsLoading) {
		ctx.patchState({ isLoading: payload.isLoading });
	}

	@Action(TrackTimeDeclineRequest)
	declineTrackTimeRequest(ctx: StateContext<TrackTimeEditRequestModel>, { payload }: TrackTimeDeclineRequest) {
		return this.jobsHttpService.declineTrackTimeEditRequest(payload.id).subscribe(() => {
			ctx.patchState({ editRequests: ctx.getState().editRequests.filter((r) => r.id !== payload.id) });
		});
	}

	@Action(SetDefaultState)
	setDefault(ctx: StateContext<TrackTimeEditRequestModel>) {
		return ctx.setState({
			editRequests: [],
			isLoading: false,
		});
	}
}
