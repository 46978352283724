import { Injectable } from '@angular/core';
import { ResourceHttpService } from 'src/app/shared/resource-http.service';
import { HttpClient } from '@angular/common/http';
import { ICrewJob } from '../components/edit-labor-crew/edit-labor-crew-jobs/edit-labor-crew-jobs.component';

@Injectable({
	providedIn: 'root',
})
export class LaborCrewsHttpService extends ResourceHttpService {
	constructor(http: HttpClient) {
		super(http, 'labor/crews');
	}

	getCrewJobs(crewId: number) {
		return this.http.get<ICrewJob[]>(`${this.entityUrl}/${crewId}/jobs`);
	}

	saveCrewJob(body: ICrewJob) {
		return this.http.post<any>(`${this.entityUrl}/jobs`, body);
	}

	removeCrewJob(id: string) {
		return this.http.delete<any>(`${this.entityUrl}/jobs/${id}`);
	}
}
