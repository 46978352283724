<div class="modal">
	<div class="modal-dialog modal-xl" role="dialog" aria-hidden="true">
		<div class="modal-content">
			<div class="modal-header">
				<button (click)="cancel()" aria-label="Close" class="close" type="button">
					<clr-icon aria-hidden="true" shape="close"></clr-icon>
				</button>
				<h3 class="modal-title">{{'Active Track Time Edit Requests' | translate}}</h3>
			</div>
			<div class="modal-body">
				<app-spinner *ngIf="isLoading" [size]="1"></app-spinner>

				<clr-datagrid *ngIf="!isLoading" class="grid">
					<clr-dg-column>Created By</clr-dg-column>
					<clr-dg-column>Created At</clr-dg-column>
					<clr-dg-column>Employee</clr-dg-column>
					<clr-dg-column style="width: 120px;">Note</clr-dg-column>
					<clr-dg-column>Assigned Users</clr-dg-column>
					<clr-dg-column style="width: 150px;"></clr-dg-column>

					<clr-dg-row *ngFor="let editRequest of editRequests">
						<clr-dg-cell>
							{{getUserNames([editRequest.createdBy])}}
						</clr-dg-cell>
						<clr-dg-cell>{{editRequest.createdAt | timezone | date}}</clr-dg-cell>
						<clr-dg-cell>{{editRequest.employeeName}}</clr-dg-cell>
						<clr-dg-cell>{{editRequest.note}}</clr-dg-cell>
						<clr-dg-cell>{{getUserNames(getUserIds(editRequest.users))}}</clr-dg-cell>
						<clr-dg-cell>
							<div class="action-wrapper">
								<button
								class="btn btn-sm btn-primary action-btn"
								(click)="navigateToTheDaily(editRequest)">
								Open
							</button>
							<button class="btn btn-icon btn-danger btn-sm action-btn" (click)="declineTrackTimeRequest(editRequest.id)">
								<clr-icon shape="times"></clr-icon>
							</button>
							</div>
						</clr-dg-cell>
					</clr-dg-row>
				</clr-datagrid>
			</div>
		</div>
	</div>
</div>
