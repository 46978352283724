<clr-main-container>
	<clr-alert *ngIf="user?.needToShowNews" (clrAlertClosedChange)="newsClosed()" [clrAlertType]="'success'" [clrAlertAppLevel]="true">
		<clr-alert-item>
			<span class="alert-text">{{'News Available!' | translate}}</span>
			<div class="alert-actions">
				<button (click)="showNews()" class="btn alert-action">{{'Open' | translate}}</button>
			</div>
		</clr-alert-item>
	</clr-alert>
	<app-setup-wizard *ngIf="!hideWizard"></app-setup-wizard>
	<app-chat></app-chat>
	<clr-header class="header header-6">
		<div class="branding clr-align-items-center">
			<img class="main-logo" [src]="logoImage" />
			<span *ngIf="user.companyRegionName" class="title">{{user.companyRegionName}}</span>
		</div>
		<div class="header-nav">
			<a *ngIf="canAccessMeasureTool" class="nav-link nav-text" [routerLink]="['/measure']">
				<fa-icon icon="map-marked-alt"></fa-icon>
				{{'Measure Tool' | translate}}
			</a>
			<a *ngIf="canAccessTodos" class="nav-link nav-text" [routerLink]="['/tools/todos']" routerLinkActive="active">
				<fa-icon icon="check-square"></fa-icon>
				{{'Notes' | translate}}
			</a>
		</div>
		<span class="page-title">{{pageTitle}}</span>
		<div class="search-container">
			<div *ngIf="canAccessSmartSearch" class="search">
				<app-smart-search></app-smart-search>
			</div>
		</div>
		<div class="header-actions">
			<clr-dropdown>
				<button class="btn btn-sm btn-sq btn-link header-action-dropdown" clrDropdownTrigger>
					<clr-icon shape="tasks"></clr-icon>
					<span class="badge badge-info">{{paveAmericaClientRequestsCount + siteReportsCount + trackTimeRequestsCount}}</span>
				</button>
				<clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
					<button *ngIf="canReviewPaveAmericaClientRequests && isPaveAmericaCompany" clrDropdownItem (click)="openPaveAmericaClientRequests()">
						Pave America Requests <span class="badge badge-info">{{paveAmericaClientRequestsCount}}</span>
					</button>
					<button clrDropdownItem (click)="openSiteReport()">
						Site Reports <span class="badge badge-info">{{siteReportsCount}}</span>
					</button>
					<button *ngIf="canEditTrackTime" clrDropdownItem (click)="openTrackTimeEditRequests()">
						Track Time Requests <span class="badge badge-info">{{trackTimeRequestsCount}}</span>
					</button>
				</clr-dropdown-menu>
			</clr-dropdown>
			<button *ngIf="chatConnected" class="btn btn-sm btn-sq btn-icon btn-link header-action-btn" (click)="openNotifications()">
				<clr-icon shape="bell"></clr-icon>
				<span class="badge badge-info">{{systemNotificationsCount}}</span>
			</button>
			<clr-dropdown *ngIf="canEditCompanyProfile">
				<button clrDropdownTrigger>
					<clr-icon shape="cog"></clr-icon>
					<clr-icon shape="caret down"></clr-icon>
				</button>
				<clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
					<a routerLink="/setup/company-profile" clrDropdownItem routerLinkActive="active">{{'Company Profile' | translate}}</a>
					<a routerLink="/setup/payment-profile" clrDropdownItem routerLinkActive="active">{{'Payment Profile' | translate}}</a>
					<a routerLink="/setup/web-form" clrDropdownItem routerLinkActive="active">{{'Web Form Settings' | translate}}</a>
					<a routerLink="/setup/offices" clrDropdownItem routerLinkActive="active">{{'Offices Settings' | translate}}</a>
					<a routerLink="/setup/import-export" clrDropdownItem>{{'Import/Export' | translate}}</a>
					<a routerLink="/setup/users" routerLinkActive="active" clrDropdownItem>{{'Manage Users' | translate}}</a>
				</clr-dropdown-menu>
			</clr-dropdown>
			<clr-dropdown>
				<button clrDropdownTrigger>
					<span class="nav-text">{{user?.name}}</span>
					<clr-icon shape="user"></clr-icon>
					<clr-icon shape="caret down"></clr-icon>
				</button>
				<clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
					<ng-container *ngIf="user?.companies?.length > 1">
						<h4 class="dropdown-header">Company</h4>
						<clr-dropdown>
							<div clrDropdownTrigger>{{user?.companyName}}</div>
							<clr-dropdown-menu clrPosition="left">
								<ng-container *ngFor="let c of user?.companies">
									<button
										[disabled]="!c.userIsActive"
										[ngClass]="c.id === user.companyId ? 'active' : ''"
										(click)="switchCompany(c.id)"
										class="company-switch-button"
										clrDropdownItem>
										{{c.name}}
									</button>
								</ng-container>
							</clr-dropdown-menu>
						</clr-dropdown>
						<div class="dropdown-divider" role="separator"></div>
					</ng-container>
					<a clrDropdownItem [routerLink]="['/tools/site-reports']" routerLinkActive="active">{{'Site Reports' | translate}}</a>
					<a
						*ngIf="canAccessExecutiveReports"
						clrDropdownItem
						[routerLink]="['/executive-reports/reports']"
						routerLinkActive="active"
						>{{'Sales Reports' | translate}}</a
					>
					<a
						*ngIf="canAccessPayrollReport"
						clrDropdownItem
						[routerLink]="['/executive-reports/payroll']"
						routerLinkActive="active"
						>{{'Payroll Report' | translate}}</a
					>
					<a
						*ngIf="canAccessExecutiveReports"
						clrDropdownItem
						[routerLink]="['/executive-reports/job-pricing-statistic']"
						routerLinkActive="active"
						>{{'Pricing Statistic Report' | translate}}</a
					>
					<a
						*ngIf="canAccessExecutiveReports"
						clrDropdownItem
						[routerLink]="['/executive-reports/material-report']"
						routerLinkActive="active"
						>{{'Material Report' | translate}}</a
					>
					<clr-dropdown *ngIf="canAccessExecutiveReports">
						<button clrDropdownTrigger>Job Costs Report</button>
						<clr-dropdown-menu clrPosition="left">
							<a clrDropdownItem [routerLink]="['/executive-reports/equipments-report-all-costs']" routerLinkActive="active">
								{{'All Costs' | translate}}</a
							>
							<a
								*ngFor="let block of possibleBlocks"
								clrDropdownItem
								[routerLink]="['/executive-reports/equipments-report', block.id]"
								routerLinkActive="active">
								{{block.name | translate}}</a
							>
							<a clrDropdownItem [routerLink]="['/executive-reports/equipments-report/overhead']" routerLinkActive="active">
								{{'Overhead' | translate}}</a
							>
						</clr-dropdown-menu>
					</clr-dropdown>
					<clr-dropdown *ngIf="canAccessExecutiveReports">
						<button clrDropdownTrigger>Daily Jobs Report</button>
						<clr-dropdown-menu clrPosition="left">
							<a clrDropdownItem [routerLink]="['/executive-reports/daily-jobs']">{{'By Job' | translate}}</a>
							<a clrDropdownItem [routerLink]="['/executive-reports/daily-jobs-wo']">{{'By Work Order' | translate}}</a>
						</clr-dropdown-menu>
					</clr-dropdown>
					<a
						*ngIf="canAccessExecutiveReports"
						clrDropdownItem
						[routerLink]="['/executive-reports/daily-crews']"
						routerLinkActive="active"
						>{{'Daily Crew Report' | translate}}</a
					>
					<a
						*ngIf="canAccessExecutiveReports"
						clrDropdownItem
						[routerLink]="['/executive-reports/profitability']"
						routerLinkActive="active"
						>{{'Profitability' | translate}}</a
					>
					<a
						*ngIf="canAccessExecutiveReports"
						clrDropdownItem
						[routerLink]="['/executive-reports/job-statistic']"
						routerLinkActive="active"
						>{{'Job Statistic' | translate}}</a
					>
					<a
						*ngIf="canAccessExecutiveReports"
						clrDropdownItem
						[routerLink]="['/executive-reports/bids-lost-survey']"
						routerLinkActive="active"
						>{{'Bids Lost Survey Report' | translate}}</a
					>
					<a
						*ngIf="canAccessExecutiveReports"
						clrDropdownItem
						[routerLink]="['/executive-reports/job-survey']"
						routerLinkActive="active"
						>{{'Job Survey Report' | translate}}</a
					>
					<a
						*ngIf="canAccessCompanyFiles"
						clrDropdownItem
						[routerLink]="['/company-files']"
						routerLinkActive="active"
						>{{'Company Files' | translate}}</a
					>
					<a clrDropdownItem target="_blank" href="https://www.pavementsoft.com/pavementsoft-contact">{{'Live Support' | translate}}</a>
					<a [routerLink]="['/setup/users/edit', user?.id]" routerLinkActive="active" clrDropdownItem>{{'User Profile' | translate}}</a>
					<button (click)="showNews()" clrDropdownItem>{{'News' | translate}}</button>
					<a
						*ngIf="canConnectQbDesktop"
						[routerLink]="['/setup/qb-desktop']"
						routerLinkActive="active"
						clrDropdownItem
						>{{'QB Desktop' | translate}}</a
					>
					<a *ngIf="hideWizard" (click)="showSetupGuide()" clrDropdownItem>{{'Setup Guide' | translate}}</a>
					<a routerLink="/auth/logout" clrDropdownItem>{{'Logout' | translate}}</a>
				</clr-dropdown-menu>
			</clr-dropdown>
			<div *ngIf="uploadingFilesCount" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm tooltip-bottom-left">
				<clr-spinner *ngIf="uploadingFilesCount" [clrSmall]="true" [clrInline]="true">Uploading...</clr-spinner>
				<span class="tooltip-content">Uploading {{uploadingFilesCount}} files</span>
			</div>
		</div>
	</clr-header>
	<div class="content-container">
		<div class="content-area">
			<router-outlet></router-outlet>
		</div>
		<clr-vertical-nav [clr-nav-level]="1" [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="navigationCollapsed">
			<div class="workflow-outer">
				<div class="inner">
					<a *ngIf="canAccessOpportunities" title="Add Opportunity" clrVerticalNavLink routerLink="/workflow/opportunities/new">
						<fa-icon clrVerticalNavIcon icon="plus-circle" [fixedWidth]="true" class="action-icon"></fa-icon>
						Add Opportunity
					</a>
					<a *ngIf="canAccessBids" title="Add Bid" clrVerticalNavLink routerLink="/workflow/bids/new">
						<fa-icon clrVerticalNavIcon icon="clipboard-list" [fixedWidth]="true" class="action-icon"></fa-icon>
						Add Bid
					</a>
				</div>
			</div>
			<a *ngIf="canAccessDashboard" title="{{'Dashboard' | translate}}" clrVerticalNavLink routerLink="/workflow/dashboard" routerLinkActive="active">
				<fa-icon clrVerticalNavIcon icon="tachometer-alt" [fixedWidth]="true"></fa-icon>
				{{'Dashboard' | translate}}
			</a>
			<a
				*ngIf="user.employeeId"
				title="{{'Employee Dashboard' | translate}}"
				clrVerticalNavLink
				routerLink="/workflow/employee-dashboard"
				routerLinkActive="active">
				<fa-icon clrVerticalNavIcon icon="user-graduate" [fixedWidth]="true"></fa-icon>
				{{'Employee Dashboard' | translate}}
			</a>
			<div class="workflow-outer">
				<div class="inner">
					<a *ngIf="canReviewWebFormLeads" title="{{'Leads' | translate}}" clrVerticalNavLink routerLink="/workflow/leads" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="hand-holding-usd" [fixedWidth]="true"></fa-icon>
						<div class="clr-row clr-no-gutters">
							<div class="clr-col">
								{{'Leads' | translate}}
							</div>
							<div class="clr-col-auto">
								<span class="badge badge-info">{{leadsCount}}</span>
							</div>
						</div>
					</a>
					<clr-vertical-nav-group *ngIf="canPartnershipData" title="{{'Partnership' | translate}}" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="handshake" [fixedWidth]="true"></fa-icon>
						{{'Partnership' | translate}}
						<clr-vertical-nav-group-children>
							<a title="{{'Sent' | translate}}" clrVerticalNavLink routerLink="/workflow/intercompany-requests-sent" routerLinkActive="active">
								<fa-icon clrVerticalNavIcon icon="paper-plane" [fixedWidth]="true"></fa-icon>
								{{'Sent' | translate}}
							</a>
							<a
								title="{{'Received' | translate}}"
								clrVerticalNavLink
								routerLink="/workflow/intercompany-requests-received"
								routerLinkActive="active">
								<fa-icon clrVerticalNavIcon icon="envelope" [fixedWidth]="true"></fa-icon>
								{{'Received' | translate}}
							</a>
						</clr-vertical-nav-group-children>
					</clr-vertical-nav-group>
					<a
						*ngIf="canAccessOpportunities"
						title="{{'Opportunities' | translate}}"
						clrVerticalNavLink
						routerLink="/workflow/opportunities"
						routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="plus-circle" [fixedWidth]="true"></fa-icon>
						{{'Opportunities' | translate}}
					</a>
					<a *ngIf="canAccessBids" title="{{'Bids' | translate}}" clrVerticalNavLink routerLink="/workflow/bids" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="clipboard-list" [fixedWidth]="true"></fa-icon>
						{{'Bids' | translate}}
					</a>
					<a *ngIf="canAccessJobs" title="{{'Jobs' | translate}}" clrVerticalNavLink routerLink="/workflow/jobs" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="wrench" [fixedWidth]="true"></fa-icon>
						{{'Jobs' | translate}}
					</a>
					<a *ngIf="canAccessSchedule" title="{{'Schedule' | translate}}" clrVerticalNavLink routerLink="/schedule" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="calendar-alt" [fixedWidth]="true"></fa-icon>
						{{'Schedule' | translate}}
					</a>
					<a
						*ngIf="canAccessExecutiveReports"
						title="{{'Dailies' | translate}}"
						clrVerticalNavLink
						routerLink="/executive-reports/daily-jobs"
						routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="sun" [fixedWidth]="true"></fa-icon>
						{{'Dailies' | translate}}
					</a>
				</div>
			</div>
			<clr-vertical-nav-group *ngIf="canAccessTrackTime" title="{{'Track Time' | translate}}" routerLinkActive="active">
				<fa-icon clrVerticalNavIcon icon="stopwatch" [fixedWidth]="true"></fa-icon>
				{{'Track Time' | translate}}
				<clr-vertical-nav-group-children>
					<a title="{{'Employees' | translate}}" clrVerticalNavLink routerLink="/workflow/track-time-employees" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="users" [fixedWidth]="true"></fa-icon>
						{{'Employees' | translate}}
					</a>
					<a title="{{'Trucks' | translate}}" clrVerticalNavLink routerLink="/workflow/track-time-trucks" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="truck" [fixedWidth]="true"></fa-icon>
						{{'Trucks' | translate}}
					</a>
				</clr-vertical-nav-group-children>
			</clr-vertical-nav-group>
			<clr-vertical-nav-group *ngIf="canAccessCRM" title="{{'CRM' | translate}}" routerLinkActive="active">
				<fa-icon clrVerticalNavIcon icon="address-card" [fixedWidth]="true"></fa-icon>
				{{'CRM' | translate}}
				<clr-vertical-nav-group-children>
					<a title="{{'Clients' | translate}}" clrVerticalNavLink routerLink="/crm/clients" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="users" [fixedWidth]="true"></fa-icon>
						{{'Clients' | translate}}
					</a>
					<a title="{{'Properties' | translate}}" clrVerticalNavLink routerLink="/crm/properties" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="building" [fixedWidth]="true"></fa-icon>
						{{'Properties' | translate}}
					</a>
					<a title="{{'Managers' | translate}}" clrVerticalNavLink routerLink="/crm/managers" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="user-tie" [fixedWidth]="true"></fa-icon>
						{{'Managers' | translate}}
					</a>
					<a
						*ngIf="canAccessMassEmail"
						title="{{'Mass Email' | translate}}"
						clrVerticalNavLink
						routerLink="/crm/mass-email"
						routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="mail-bulk" [fixedWidth]="true"></fa-icon>
						{{'Mass Email' | translate}}
					</a>
				</clr-vertical-nav-group-children>
			</clr-vertical-nav-group>
			<clr-vertical-nav-group *ngIf="canEditSettings || canEditEmployeeData" title="{{'Production' | translate}}" routerLinkActive="active">
				<fa-icon clrVerticalNavIcon icon="tractor" [fixedWidth]="true"></fa-icon>
				{{'Production' | translate}}
				<clr-vertical-nav-group-children>
					<a clrVerticalNavLink *ngIf="canEditSettings" routerLink="/setup/labor-crews" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="users" [fixedWidth]="true"></fa-icon>
						{{'Crews' | translate}}
					</a>
					<a clrVerticalNavLink *ngIf="canEditEmployeeData" routerLink="/setup/labor-employees" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="user" [fixedWidth]="true"></fa-icon>
						{{'Employees' | translate}}
					</a>
				</clr-vertical-nav-group-children>
			</clr-vertical-nav-group>
			<clr-vertical-nav-group *ngIf="canEditSettings" title="{{'Settings' | translate}}" routerLinkActive="active">
				<fa-icon clrVerticalNavIcon icon="cogs" [fixedWidth]="true"></fa-icon>
				{{'Settings' | translate}}
				<clr-vertical-nav-group-children>
					<a clrVerticalNavLink routerLink="/setup/categories" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="boxes" [fixedWidth]="true"></fa-icon>
						{{'Categories' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/proposal-items" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="tasks" [fixedWidth]="true"></fa-icon>
						{{'Proposal Items' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/proposal-attachments" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="images" [fixedWidth]="true"></fa-icon>
						{{'Proposal Attachments' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/proposal-sets" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="toolbox" [fixedWidth]="true"></fa-icon>
						{{'Proposal Sets' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/labor-divisions" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="sitemap" [fixedWidth]="true"></fa-icon>
						{{'Labor Divisions' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/business-divisions" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="sitemap" [fixedWidth]="true"></fa-icon>
						{{'Business Divisions' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/concrete-standards" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="drafting-compass" [fixedWidth]="true"></fa-icon>
						{{'Concrete Standards' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/material-fees" routerLinkActive="active">
						<!-- <fa-icon clrVerticalNavIcon icon="drafting-compass" [fixedWidth]="true"></fa-icon> -->
						{{'Material Fees' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/opportunity-sources" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="tty" [fixedWidth]="true"></fa-icon>
						{{'Opportunity Sources' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/opportunity-types" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="chess" [fixedWidth]="true"></fa-icon>
						{{'Opportunity Types' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/opportunity-statuses" routerLinkActive="active">
						<!-- <fa-icon clrVerticalNavIcon icon="chess" [fixedWidth]="true"></fa-icon> -->
						{{'Opportunity Statuses' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/todo-categories" routerLinkActive="active">
						<!-- <fa-icon clrVerticalNavIcon [fixedWidth]="true"></fa-icon> -->
						{{'Note Categories' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/job-statuses" routerLinkActive="active">
						<!-- <fa-icon clrVerticalNavIcon icon="chess" [fixedWidth]="true"></fa-icon> -->
						{{'Job Statuses' | translate}}
					</a>
					<a *ngIf="canEditCompanyProfile" clrVerticalNavLink routerLink="/setup/groups" routerLinkActive="active">
						{{'Groups' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/employee-work-classes" routerLinkActive="active">
						{{'Employee Work Classes' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/site-report-forms" routerLinkActive="active"> Site Report Forms </a>
					<a clrVerticalNavLink routerLink="/setup/direct-costs-sets/equipment" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="tools" [fixedWidth]="true"></fa-icon>
						Equipment Sets
					</a>
					<a clrVerticalNavLink routerLink="/setup/direct-costs-sets/add_costs" routerLinkActive="active">
						<fa-icon clrVerticalNavIcon icon="tools" [fixedWidth]="true"></fa-icon>
						Additional Costs Sets
					</a>
				</clr-vertical-nav-group-children>
			</clr-vertical-nav-group>
			<clr-vertical-nav-group title="{{'Direct Costs' | translate}}" *ngIf="canEditDirectCosts" routerLinkActive="active">
				<fa-icon clrVerticalNavIcon icon="hard-hat" [fixedWidth]="true"></fa-icon>
				{{'Direct Costs' | translate}}
				<clr-vertical-nav-group-children>
					<a
						*ngFor="let costsBlock of possibleBlocks"
						clrVerticalNavLink
						[routerLink]="['/setup/direct-costs', costsBlock.id]"
						routerLinkActive="active">
						<!-- <fa-icon clrVerticalNavIcon icon="sitemap" [fixedWidth]="true"></fa-icon> -->
						{{costsBlock.name}}
					</a>
				</clr-vertical-nav-group-children>
			</clr-vertical-nav-group>
			<clr-vertical-nav-group *ngIf="canEditDirectCosts" routerLinkActive="active">
				<fa-icon clrVerticalNavIcon icon="industry" [fixedWidth]="true"></fa-icon>
				{{'Plants' | translate}}
				<clr-vertical-nav-group-children>
					<a clrVerticalNavLink routerLink="/setup/company-plants/asphalt" routerLinkActive="active">
						{{'Asphalt' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/company-plants/concrete" routerLinkActive="active">
						{{'Concrete' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/company-plants/stone" routerLinkActive="active">
						{{'Stone' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/company-plants/dump" routerLinkActive="active">
						{{'Dumps' | translate}}
					</a>
					<a clrVerticalNavLink routerLink="/setup/company-plants/cement" routerLinkActive="active">
						{{'Cement' | translate}}
					</a>
				</clr-vertical-nav-group-children>
			</clr-vertical-nav-group>
			<a
				*ngIf="canEditDirectCosts"
				title="{{'Asphalt Index' | translate}}"
				clrVerticalNavLink
				routerLink="/setup/asphalt-index"
				routerLinkActive="active">
				<fa-icon clrVerticalNavIcon icon="coins" [fixedWidth]="true"></fa-icon>
				{{'Asphalt Index' | translate}}
			</a>
			<a *ngIf="canAccessJobs" title="{{'Vendors Map' | translate}}" clrVerticalNavLink routerLink="/setup/vendors-map" routerLinkActive="active">
				<fa-icon clrVerticalNavIcon icon="map-marked-alt" [fixedWidth]="true"></fa-icon>
				{{'Vendors Map' | translate}}
			</a>
		</clr-vertical-nav>
	</div>
</clr-main-container>
